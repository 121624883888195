<template>

<app-content :loading="is.loading">

	<app-content-body v-if="!is.loading" :is-call="true">

		<app-content-call v-if="!is.registered" :loading="is.confirming" icon="volunteers" theme="blue" title="Register Interest" text="Do you want to help make our convention an even better experience for your fellow attendees? Then volunteering might be for you! Let us know if you're interested and we'll be in touch." button="Submit" v-on:click="onConfirmClick">
		
			<app-input-text :autogrow="true" v-model="model.about" placeholder="Optional: Describe any volunteering experience you have..." class="delete-reason" />

		</app-content-call>

		<app-content-call v-if="is.registered" :loading="is.confirming" icon="volunteers" theme="green" title="Thank you!" text="We have received your registration and will be in touch." />
	
	</app-content-body>

</app-content>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				loading: true,
				confirming: false,
				registered: false
			},
			model: {
				about: ''
			}
		}

	},

	created: function() {

		if (this.$canVolunteer || !this.$settings.volunteers.signups) this.$router.push({
			name: 'Convention.Dashboard'
		})

		this.load()

	},

	methods: {

		onConfirmClick: async function() {

			this.is.confirming = true

			this.$api.post('convention/volunteers/register', {
				about: this.model.about
			}).then(function() {

				this.is.confirming = false
				this.is.registered = true

			}.bind(this))

		},

		load: function() {

			this.$api.get('convention/volunteers/register').then(function(response) {

				this.is.registered = response.registered
				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.delete-reason {
	margin-top: 20px;
	width: 100%;
}

</style>
